import React, { useContext, useEffect, useRef, useState } from "react";
import AccGraph from "./accGraph/AccGraph";
import ChecksGraph from "./checksGraph/ChecksGraph";
import styles from "./dropdownData.module.scss";
import TempGraph from "./tempGraph/TempGraph";
import Comments from "../../Comments/Comments";
import styles2 from "../../RcFreezer/freezerStyles.module.scss";
import FreezeTempGraph from "../../FreezeTempGraph/FreezeTempGraph";
import { GET_REFRIGERATION } from "../../../queries";
import { useLazyQuery } from "@apollo/client";
import { AiOutlineDownload } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { saveAs } from "file-saver";
import { fetchRCStaticPdf, formatDate } from "../../../helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { language } from "../../../const";
import { DatesContext } from "../../../context/dates/Dates";
import { Brush } from "recharts";
import {
  ZoomContext,
  ZoomProvider,
} from "../../../context/grafico/ZoomProvider";

const DropdownData = ({
  comments,
  shipment_id,
  expanded,
  index,
  gmt,
  rc_freeze,
  refrigeration_id,
  ref_id,
  qr,
  name,
  temp_tange,
  freezer_temp_range,
  data,
  onSelected,

  // getInterval,
}) => {
  //importo datos de context
  const { setRcStaticFrom, setRcStaticTo } = useContext(DatesContext);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Obtener componentes de la fecha y hora
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    let ampm = "am";

    // Convertir las horas al formato de 12 horas y determinar am/pm
    if (hours > 12) {
      hours -= 12;
      ampm = "pm";
    }

    // Combinar los componentes para formar el formato deseado
    const formattedDate = `${month}/${day}/${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} - ${formattedTime}`;
  }
  //queries / mutations-----------------------------//
  const [
    getRefrigeration,
    {
      loading: refrigerationsLoading,
      error: refrigerationsError,
      data: refrigerationsResult,
    },
  ] = useLazyQuery(GET_REFRIGERATION);
  //estados...............................//
  const [interlvaFilter, setIntervalFilter] = useState({
    from: null,
    to: null,
  });
  const [intervalReadings, setIntervalReadings] = useState([]);
  const [alerts, setAlerts] = useState([]);
const [refetchComments, setRefetchComments] = useState(false)
  useEffect(() => {
    setIntervalFilter({ from: null, to: null });
  }, [expanded]);

 
  //***************handlers*********************** */

  const handleIntervalFilter = (date, name) => {
   
    setIntervalFilter({
      ...interlvaFilter,
      [name]: date,
    });
    //seteo las fechas en el context para usar en el componente FreezerAlertsModal
    if (name === "from") setRcStaticFrom(formatDate(date));
    if (name === "to") setRcStaticTo(formatDate(date));
  };

  const downloadCsv = (e, data) => {
    e.stopPropagation();
    e.preventDefault();

    if (data?.length === 0) {
      toast.error("Intervalo de fechas inválido", {
        position: toast.POSITION.LEFT,
      });
      return;
    }

    let csvContent = `RCS:${qr}-Name:${name} \n`;

    csvContent += "READINGS\n";
    csvContent += "timestamp,value\n";
    csvContent += `Start Date: ${interlvaFilter.from}\n`;
    csvContent += `End Date: ${
      interlvaFilter.to !== null ? interlvaFilter.to : ""
    }\n`;
    data.forEach((reading) => {
      csvContent += `\n${formatTimestamp(reading.time)},${
        reading?.cv !== null ? reading?.cv : reading?.temp
      }`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `CSV QR:${qr}.csv`);
  };
  const today = new Date();

  function formatearFecha(today) {
    let fecha = new Date(today);
    let año = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();

    // Asegurarse de que el mes y el día sean de dos dígitos
    mes = mes < 10 ? "0" + mes : mes;
    dia = dia < 10 ? "0" + dia : dia;

    return `${año}-${mes}-${dia}`;
  }
const refetchGralComments=()=>{
  setRefetchComments(!refetchComments)
}
  return (
    <>
      {rc_freeze ? (
        <>
          <div className={styles2.searchAndDataContainer}>
            <div className={styles2.freezeTempGraphContainer}>
              <div className={styles2.from_to_container}>
                <div className={styles.datePickerContainer}>
                  <label htmlFor="fromDate" className={styles.label}>
                    {language === "en" ? "From" : "Desde"}
                  </label>
                  <DatePicker
                    id="fromDate"
                    selected={interlvaFilter.from ? interlvaFilter.from : today}
                    type="date"
                    name="from"
                    onChange={(date) => handleIntervalFilter(date, "from")}
                    dateFormat="yyyy-MM-dd"
                    className={styles.datePicker}
                    maxDate={new Date()}
                  />
                  <label htmlFor="toDate" className={styles.label}>
                    {language === "en" ? "To" : "Hasta"}
                  </label>
                  <DatePicker
                    id="fromDate"
                    selected={interlvaFilter.to ? interlvaFilter.to : today}
                    type="date"
                    name="to"
                    onChange={(date) => handleIntervalFilter(date, "to")}
                    dateFormat="yyyy-MM-dd"
                    className={styles.datePicker}
                    maxDate={new Date()}
                    
                  />

                  <button
                    onClick={(e) => downloadCsv(e, intervalReadings)}
                    className={styles.buttonDownload}
                  >
                    <AiOutlineDownload size="2vw" />
                    <br />
                    CSV
                  </button>
                  <button
                    onClick={(e) =>
                      fetchRCStaticPdf(
                        e,
                        ref_id,
                        refrigeration_id,
                        interlvaFilter.from
                          ? formatearFecha(interlvaFilter.from)
                          : formatearFecha(today),
                        interlvaFilter.to
                          ? formatearFecha(interlvaFilter.to)
                          : formatearFecha(today)
                      )
                    }
                    className={styles.buttonDownload}
                  >
                    <AiOutlineDownload size="2vw" />
                    <br />
                    PDF
                  </button>
                </div>
              </div>

              {expanded[index] && (
                <FreezeTempGraph
                  refrigeration_id={refrigeration_id}
                 // intervalReadings={intervalReadings}
                  temp_tange={temp_tange}
                  freezer_temp_range={freezer_temp_range}
                  expanded={expanded[index]}
                  setIntervalFilter={setIntervalFilter}
                  gmt={gmt}
                  alerts={alerts}
                  // from={interlvaFilter.from}
                  // to={interlvaFilter.to}
                  loading={refrigerationsLoading}
                  interlvaFilter={interlvaFilter}
                  qr={qr}
                  refetchGralComments={refetchGralComments}
                />
              )}
            </div>

            <div className={styles2.filter_and_comments_container}>
              {/* COMMENTS-------- */}

              {expanded[index] && (
                <Comments
                  rc_freeze={rc_freeze}
                  qr={qr}
                  refrigeration_id={refrigeration_id}
                  refetchComments={refetchComments}
                   from={interlvaFilter.from}
                   to={interlvaFilter.to}
                  
                />
              )}
            </div>

            <div />
          </div>
          <ToastContainer />
        </>
      ) : (
        <>
          <div
            className={styles.dropdownContainer}
            style={{
              background: index % 2 === 0 ? "var(--white)" : "#fafafa",
            }}
          >
            <div className={styles.graphsContainer}>
              {expanded[index] && (
                <TempGraph shipment_id={shipment_id} gmt={gmt} />
              )}
            </div>
            {/* side (checkpoints and comments) */}
            <div className={styles.checkpointsAndcommentsContainer}>
              <div className={styles.checkpointsContainer}>
                {expanded[index] && (
                  <ChecksGraph
                    shipment_id={shipment_id}
                    gmt={gmt}
                    checkpointsData={data[index]}
                    onSelected={onSelected}
                  />
                )}
              </div>

              <div className={styles.commentsSectionContainer}>
                <Comments shipment_id={shipment_id} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DropdownData;
{
  /* <div className={styles.accContainer}>
                  {expanded[index] && (
                    <AccGraph shipment_id={shipment_id} gmt={gmt} />
                  )}

                </div> */
}
