import React, { useContext, useEffect } from "react";
import { DatesContext } from "../../../context/dates/Dates";
import styles from "./dateFromTo.module.scss";
import { Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { language } from "../../../const";
import { FormattedMessage } from "react-intl";
import { subDays } from 'date-fns'; 

const DatePickerFromTo = ({ error, setError }) => {
  // Importar datos del contexto
  const { from, to, setFrom, setTo } = useContext(DatesContext);
  //para limitar fechas
  const today = new Date();
  const minDate = subDays(today, 365); // 365 días atrás desde hoy
  useEffect(() => {
    handleLast15Days();
  }, [error]); // Actualiza los datos cuando hay un error

  const handleDateChange = (date, name) => {
    if (name === "from") {
      setFrom(date);
    } else if (name === "to") {
      setTo(date);
    }
  };

  const handleLast15Days = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
    fifteenDaysAgo.setHours(0, 0, 0, 0);

    setFrom(fifteenDaysAgo);
    setTo(date);
  };
  const handleToday = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    setFrom(date);
    setTo(date);
  };
  return (
    <div className={styles.container} style={{/* border:"solid red 1px", */ position:"relative",left:"56vw"}}>
      <div className={styles.datePickerContainer}>
        <label htmlFor="fromDate" className={styles.label}>
          {language === "en" ? "From" : "Desde"}
        </label>
        <DatePicker
          id="fromDate"
          selected={from}
          onChange={(date) => handleDateChange(date, "from")}
          dateFormat="yyyy-MM-dd"
          className={styles.datePicker}
          minDate={minDate}
        />
      </div>
      <div className={styles.datePickerContainer}>
        <label htmlFor="toDate" className={styles.label}>
          {language === "en" ? "To" : "Hasta"}
        </label>
        <DatePicker
          id="toDate"
          selected={to}
          onChange={(date) => handleDateChange(date, "to")}
          dateFormat="yyyy-MM-dd"
          className={styles.datePicker}
        />
      </div>
      <Button variant="contained" onClick={handleToday} sx={{backgroundColor:"var(--cyan)", textTransform:"none", fontFamily: "var(--font1)"}}>
        <FormattedMessage id="today">Today</FormattedMessage>
        <br />
      </Button>
    </div>
  );
};

export default DatePickerFromTo;
