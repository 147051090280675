import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_SHIPMENT_DETAIL } from "../../../../queries";
import RegistroTemperaturaIntrusion from "../../../RegistroTemperaturaIntrusion/RegistroTemperaturaIntrusion";

const TempGraph = ({ shipment_id }) => {
  const [data, setData] = useState({});

  // query para data de graficos
  const [
    getShipment,
    {
      loading: contentLoading,
      error: contentError,
      data: contentData,
    },
  ] = useLazyQuery(GET_SHIPMENT_DETAIL);

  useEffect(() => {
    const fetchShipments = async () => {
      try {
        await getShipment({ variables: { shipment_id } });
        //  setData(contentData);
      } catch (error) {
        console.log(error);
      }
    };
    
    fetchShipments();
 
  }, [contentData]);

  return(
    <RegistroTemperaturaIntrusion
      temperature_readings={contentData?.shipment?.temperature_readings}
      intrusions={contentData?.shipment?.intrusions}
      alerts={contentData?.shipment?.alerts}
      graphOnly={true}
      ship_temp_range={contentData?.shipment?.temperature_range}
      checkpoints={contentData?.shipment?.checkpoints}
      comments={contentData?.shipment?.comments}
      shipment_id={shipment_id}
      loading={contentLoading}
      contentData={contentData}
    
    />
  );
};

export default TempGraph;
